import React from 'react';
import styles from './styles';
import Layout from '@components/common/Layouts/Landing';
import LandingHeader from '@components/LandingHeader';
import { useTheme } from '@mui/styles';
import * as Yup from 'yup';
import { Field, Form, Formik } from 'formik';
import Hero from '@assets/hero.png';
import {
	Box,
	Button,
	CircularProgress,
	Container,
	Grid,
	MenuItem,
	Stack,
	Typography,
} from '@mui/material';
import { useSnackbar } from '@components/common/Snackbar';
import { Select, TextField } from 'formik-mui';
import { graphql, Link, useStaticQuery } from 'gatsby';

const Contact: React.FC = () => {
	const theme = useTheme();
	const [openSnackbar] = useSnackbar();

	const data = useStaticQuery(graphql`
		{
			allHelpArticle {
				edges {
					node {
						_id
						title
					}
				}
			}
		}
	`);

	const {
		allHelpArticle: { edges: articles },
	} = data;

	const handleSubmit = async (values) => {
		try {
			console.log('submitted');
		} catch (error) {
			console.log(2, 'error', error);
			if (error !== 'cancelled') {
				openSnackbar(
					error?.errorMessage ?? 'An error occurred attempting to save your checkout.',
					'error'
				);
			}
		}
	};

	const initialValues: {
		name: string;
		email: string;
		topic: string;
		message: string;
	} = {
		name: '',
		email: '',
		topic: articles?.[0]?.node?.title,
		message: '',
	};

	const validationSchema = Yup.object().shape({
		name: Yup.string().required('Name is required').label('Name'),
		email: Yup.string().required('Email is required').label('Email'),
		topic: Yup.string().required('Topic is required').label('Topic'),
		message: Yup.string().required('Message is required').label('Message'),
	});

	return (
		<Layout>
			<div css={styles(theme)}>
				<LandingHeader title="Contact Us" backgroundImage={Hero} />
				<Container maxWidth="lg">
					<Box sx={{ borderRadius: '20px', p: 4 }} className="contact-form-container">
						<Typography variant="h2" sx={{ mb: 4 }}>
							Please fill out the form below
						</Typography>
						<Grid container spacing={4}>
							<Grid item lg={6}>
								<Formik
									initialValues={initialValues}
									validationSchema={validationSchema}
									onSubmit={handleSubmit}
								>
									{({ isSubmitting }) => (
										<Form className="form">
											<Stack direction="column" spacing={2}>
												<Field
													component={TextField}
													name="name"
													type="text"
													label="Name"
													fullWidth
												/>
												<Field
													component={TextField}
													name="email"
													type="text"
													label="Email"
													fullWidth
												/>
												<Field
													component={Select}
													name="topic"
													label="Topic"
													fullWidth
													variant="outlined"
												>
													{articles?.map((article, index) => (
														<MenuItem
															key={index}
															value={article?.node?._id}
														>
															{article?.node?.title}
														</MenuItem>
													))}
												</Field>
												<Field
													component={TextField}
													name="message"
													type="text"
													label="Message"
													fullWidth
													multiline
													rows={4}
												/>

												<Button
													type="submit"
													className="contact-button submit"
													fullWidth
													variant="contained"
													disabled={isSubmitting}
													startIcon={
														isSubmitting ? (
															<CircularProgress
																color="inherit"
																size={20}
															/>
														) : undefined
													}
												>
													send
												</Button>
											</Stack>
										</Form>
									)}
								</Formik>
							</Grid>
							<Grid item lg={6}>
								<Stack direction="column" spacing={2}>
									<Typography variant="body1">
										If you have any concerns or questions regarding the Wave
										Card experience then please do not hesitate to contact the
										team and we will get back to you as soon as possible.
									</Typography>
									<Typography variant="body1">
										We highly recommend browsing our
										<Link to="#" className="knowledge-link">
											Knowledge Base
										</Link>
										for your answer as it may be quicker than waiting for our
										reply.
									</Typography>
									<Typography variant="body1">
										All the best, The Wave Card Team
									</Typography>
								</Stack>
							</Grid>
						</Grid>
					</Box>
				</Container>
			</div>
		</Layout>
	);
};

export default Contact;
