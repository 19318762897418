import { css } from '@emotion/react';
import { Theme } from '@mui/material';

export default (theme: Theme) => css`
	.contact-form-container {
		box-shadow: 0px -20px 50px #00000029;
		.knowledge-link {
			color: #000;
			text-decoration: underline;
			margin: 0 4px;
		}
		.contact-button {
			background-color: #2164ff;
		}
	}
`;
